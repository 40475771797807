import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: ${props => (props.subHeading ? '0' : '80px')};
	padding-top: ${props => (props.subHeading ? '150px' : '40px')};
	${mediaQuery.tabletML`grid-template-columns: ${props =>
		props.featuredCustomer
			? ` 1.766fr 1fr;`
			: `${props.invert ? '1fr 1.766fr' : '1.766fr 1fr'}`};`};
	${mediaQuery.mobileXL`grid-template-columns: 1fr;`};
	align-items: center;

	.content,
	.inverted-content {
		display: flex;
		height: ${props => (props.justifyContent ? '100%' : 'auto')};
		flex-direction: column;
		align-items: flex-start;
		justify-content: ${props =>
			props.justifyContent ? props.justifyContent : 'flex-start'};
		order: ${props => props.changeOrder || 1};
		padding: 0 15px;
	}
	p {
		line-height: 1.5;
		margin: 0;
		font-family: ${setFontFamily.book} !important;
		font-size: ${props =>
			props.subHeading
				? `${setFontSize.tiny}`
				: `${setFontSize.verySmall} !important`};
		color: ${setColors.lightBlack} !important;
		${mediaQuery.laptopXL`
			font-size: ${setFontSize.mediumTiny} !important;
		`};
		${mediaQuery.tabletL`
			font-size: ${setFontSize.veryTiny} !important;
		`};
		${mediaQuery.mobileXL`
			font-size: ${props =>
				props.subHeading
					? `${setFontSize.veryTiny}`
					: `${setFontSize.mediumTiny} !important`};
		`};
	}

	h1,
	h2,
	p {
		text-align: left;
		padding-right: ${props =>
			props.subHeading ? (props.invert ? '0' : '40px') : `0`};
		padding-left: ${props =>
			props.subHeading ? (props.invert ? '40px' : '0') : '0'};
		${mediaQuery.mobileXL`
			text-align: ${props => (props.alignLeft ? 'left' : 'center')};
			padding-right: 0;
			padding-left: 0;
		`}
	}

	h1,
	h2 {
		color: ${setColors.lightBlack};
		line-height: ${props =>
			props.subHeading ? `1.09 !important` : `1.26 !important`};
		font-weight: ${setFontWeight.extraBold} !important;
		text-transform: none;
		letter-spacing: ${props =>
			props.subHeading || props.featuredCustomer
				? `-0.69px !important`
				: `-1.5px !important`};
		margin-top: ${props => (props.subHeading ? '10px' : '0')};
		margin-bottom: ${props => (props.subHeading ? '10px' : '14px')};
		font-family: ${props => (props.subHeading ? `${setFontFamily.bold}` : ``)};
		font-size: ${props =>
			props.subHeading ? `${setFontSize.mediumLarge}` : ``};
		${mediaQuery.laptopXL`
			font-size: ${props => (props.subHeading ? `${setFontSize.veryLarge}` : ``)};
		`};
		${mediaQuery.tabletL`
			font-size: ${props => (props.subHeading ? `${setFontSize.mediumSmall}` : ``)};
		`};
		width: 100%;
		box-sizing: border-box;
	}

	.image-holder {
		display: grid;
		padding: 0 15px;
		${mediaQuery.mobileXL`
			padding-top: ${props => (props.subHeading ? '10px' : '')};
			padding-bottom: ${props => (props.subHeading ? '15px' : '')};
		`}
		img {
			image-rendering: -webkit-optimize-contrast;
		}
	}

	.image {
		order: 1;
		${mediaQuery.mobileXL`
			order: ${props => (props.subHeading ? '-1' : '1')};
		`}
	}

	#blog-image {
		max-width: 488px;
		max-height: 488px;
		${mediaQuery.mobileXL`margin-top: 20px`}
	}

	.blog-title {
		margin: 0 0 40px 0;
		${mediaQuery.mobileXL`
			margin: 40px 0
		`}
	}
	.tags {
		display: flex;
		flex-direction: column;
		font-weight: ${setFontWeight.bolder};
		font-size: ${setFontSize.tiny};
		span {
			font-weight: ${setFontWeight.normal};
		}
	}

	@media only screen and (max-width: 767px) {
		.tags {
			margin: 0px auto;
		}
		.hide {
			display: none;
		}
	}

	${mediaQuery.laptopXL`
		padding-top: ${props => (props.subHeading ? '100px' : '40px')};
	`}

	${mediaQuery.mobileXL`

		.inverted-content{
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;}


		.inverted-content{
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

    	grid-template-columns: 1fr;
		justify-content: ${props => props.featuredCustomer && 'space-between'};
		padding-top: ${props => (props.subHeading ? '70px' : '40px')};
    `};
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 29px;
	.button-holder {
		margin-right: 20px;
		${mediaQuery.mobileXL`		
			padding-left: 61px;
    		padding-right: 61px;
		`}
		${mediaQuery.tabletXS`
			margin-right: 0px`}
	}
	${mediaQuery.mobileXL`
	width: 100%;
	justify-content: center;
	display: flex;`}
`;

export const LinkWrapper = styled.div`
	width: 100%;
	p {
		margin-top: 30px;
		display: flex;
		align-items: center;
		svg {
			height: 18px;
			width: 18px;
			margin-left: 5px;
			color: ${setColors.themeBlue};
		}
	}
	a {
		text-decoration: none;
		color: ${setColors.themeBlue};
	}
	${mediaQuery.mobileXL`
		p{
			justify-content: center;
		}
	`}
`;

export const FeaturedCustomer = styled.div`
	width: 100%;
	display: flex;
	justify-items: center;
	align-items: center;
	margin: 0.85em 0;
	p {
		font-family: ${setFontFamily.bold} !important;
		font-weight: ${setFontWeight.normal};
		font-size: ${setFontSize.verySmall} !important;
		margin-right: 10px;
	}
	${mediaQuery.mobileXL`justify-content: center;`}
`;

export const BlogTitle = styled.div`
	width: 100%;
	p {
		text-transform: ${props => (props.isUppercase ? 'uppercase' : 'unset')};
		font-family: ${setFontFamily.book} !important;
		font-weight: ${setFontWeight.normal};
		font-size: ${setFontSize.veryTiny} !important;
		margin-right: 10px;
		line-height: 1.43;
		${mediaQuery.tabletL`font-size: ${setFontSize.tiniest} !important`}
	}
`;

export const ClientInfo = styled.div`
	width: 100%;
	margin-top: 1rem;
	p {
		margin: 0rem;
		font-size: ${setFontSize.verySmall} !important;
		color: ${setColors.lightBlack};
	}
	p:nth-of-type(1) {
		font-family: ${setFontFamily.bold} !important;
		font-weight: ${setFontWeight.normal};
	}
	p:nth-of-type(2) {
		font-family: ${setFontFamily.book};
	}
`;

export const H1 = styled.h1`
	line-height: 1.26;
	font-weight: ${setFontWeight.extraBold};
	letter-spacing: -1.5px;
	color: ${setColors.themeBlue};
	font-family: ${setFontFamily.black};
	font-size: ${setFontSize.mediumLarge};
	max-width: 555px;
	margin: 0 !important;
	letter-spacing: -1.5px;

	${mediaQuery.laptopXL`font-size: ${setFontSize.tinyMedium};`}
	${mediaQuery.tabletL`font-size: ${setFontSize.veryLarge};`}
`;

export const AuthorDetails = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AuthorName = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	align-items: flex-start;
	height: 37px;
	justify-content: space-between;
`;

export const AuthorText = styled.div`
	font-size: ${setFontSize.smallest};
	font-family: ${setFontFamily.medium};
	color: ${setColors.textColor};
`;

export const NameOfAuthor = styled.div`
	font-size: ${setFontSize.tiny};
	font-family: ${setFontFamily.bold};
`;

export const BlogDetails = styled.div`
	display: flex;
	font-size: ${setFontSize.littleTiny};
	font-family: ${setFontFamily.book};
	color: ${setColors.planCardGrey};
	margin-top: 4px;
	align-items: center;
	gap: 8px;

	${mediaQuery.tablet975`
		font-size: ${setFontSize.veryTiny};
	`}

	${mediaQuery.tablet932`
		font-size: ${setFontSize.veryVeryTiny}
	`}

	${mediaQuery.tabletM`
		gap: 4px
	`}

	${mediaQuery.tabletXS`
		gap: 8px;
		font-size: ${setFontSize.littleTiny};
	`}

	${mediaQuery.mobileXXS`
		font-size: ${setFontSize.veryVeryTiny}
	`}
`;

export const BlogDetailContainer = styled.div`
	${mediaQuery.mobileXL`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		flex-direction: column;
	`}
`;
