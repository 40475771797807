import React, { useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import Button from '../Button';
import { IconNext } from '../icons/';
import { checkLink, setColors } from '../../../../utils/helpers';
import * as S from './styles';
import '../../../fonts/typography.css';
import { StyledReactModal } from '../../newly-developed-pages/style';
import { PlayButton } from '../Button/style';
import PlayButtonIcon from '../../../images/playbutton.png';

const getVideoStyle = videoBorderRadius => {
	return {
		maxHeight: '400px',
		objectFit: 'cover',
		width: '100%',
		borderRadius: videoBorderRadius || 'unset',
	};
};

export const customStyles = {
	content: {
		position: 'relative',
		display: 'inline-block',
		margin: 'auto',
		inset: '0',
		boxSizing: 'border-box',
		outline: '0',
		padding: '0',
		border: 'none',
		verticalAlign: 'middle',
		width: '100%',
		maxWidth: '900px',
	},
	overlay: {
		backgroundColor: setColors.modalOverLayColor,
		zIndex: '1050',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
		padding: '0 6px',
		display: 'flex',
	},
};

const InfoBox = ({
	image,
	title,
	subtitle,
	alignLeft,
	invert,
	hideImage,
	featuredCustomer,
	logo,
	contactName,
	contactTitle,
	justifyContent,
	subHeading,
	buttonText,
	buttonLink,
	secondaryButtonText,
	secondaryButtonLink,
	blogPost,
	blogCategory,
	publisher,
	linkText,
	link,
	videoBorderRadius,
	header,
	isHeroHeader,
	tags,
	playButtonLink,
	changeOrder,
}) => {
	const [modal, setModal] = useState(false);
	const handleOpenModal = () => {
		setModal(!modal);
	};
	const generateEmbedLink = link => {
		const url = new URL(link);
		const videoId = url.searchParams.get('v');
		return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
	};
	const generatePlayButton = (link, text) => {
		const linkType = checkLink(link);
		const url = new URL(link);
		if (linkType === 0 && url.hostname === 'www.youtube.com') {
			return (
				<PlayButton
					btnColor={setColors.themeBlue}
					as="a"
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					onClick={e => {
						e.preventDefault();
						handleOpenModal();
					}}
				>
					<img src={PlayButtonIcon} alt={text} title={text} />
					{text}
				</PlayButton>
			);
		} else {
			return (
				<Button
					btnColor={setColors.themeBlue}
					as="a"
					href={link}
					target="_self"
					rel="noopener noreferrer"
					outline="true"
				>
					{text}
				</Button>
			);
		}
	};
	const generateButton = (buttonLink, buttonText) => {
		const urlType = checkLink(buttonLink);
		if (urlType === 2) {
			return (
				<Button to={buttonLink} className="button-holder">
					{buttonText}
				</Button>
			);
		} else if (urlType === 1) {
			return (
				<Button
					as="a"
					href={buttonLink}
					target="_self"
					className="button-holder"
				>
					{buttonText}
				</Button>
			);
		} else {
			return (
				<Button
					as="a"
					href={buttonLink}
					target="_blank"
					className="button-holder"
				>
					{buttonText}
				</Button>
			);
		}
	};
	const generateLink = (link, linkText) => {
		const linkType = checkLink(link);
		if (linkType === 2) {
			return (
				<p>
					<Link to={`${link}/`}>{linkText}</Link>
					<IconNext />
				</p>
			);
		} else if (linkType === 1) {
			return (
				<p>
					<a href={link} target="_self">
						{linkText}
					</a>
					<IconNext />
				</p>
			);
		} else {
			return (
				<p>
					<a href={link} target="_blank" rel="noopener noreferrer">
						{linkText}
					</a>
					<IconNext />
				</p>
			);
		}
	};
	const getAllTags = tagsList => {
		return Object.keys(tagsList).reduce((acc, key) => {
			acc.push(...tagsList[key]);
			return acc;
		}, []);
	};
	const tagList =
		tags && Object.entries(tags).length > 0
			? getAllTags(tags)
					.map(tag => tag[0].toUpperCase() + tag.slice(1))
					.join(', ')
			: '';
	return (
		<S.Wrapper
			invert={invert}
			alignLeft={alignLeft}
			featuredCustomer={featuredCustomer}
			justifyContent={justifyContent}
			subHeading={subHeading}
			changeOrder={changeOrder}
		>
			{(invert && (
				<React.Fragment>
					<div className="image-holder">
						{image.file && image.file.contentType === 'video/mp4' ? (
							<video
								autoPlay
								muted
								loop
								style={getVideoStyle(videoBorderRadius)}
							>
								<source src={image.file.url} type={image.file.contentType} />
							</video>
						) : (
							<Img
								fluid={image.fluid}
								alt={image.title}
								imgStyle={{
									objectFit: 'fill',
									maxHeight: subHeading ? '420px' : '400px',
									borderRadius: '10px',
								}}
							/>
						)}
					</div>
					<div className="inverted-content">
						{header && (
							<S.FeaturedCustomer>
								<p className="hide">{header}</p>
							</S.FeaturedCustomer>
						)}
						{isHeroHeader ? (
							<h1 className={subHeading ? '' : 'font-black-54'}>{title}</h1>
						) : (
							<h2 className={subHeading ? '' : 'font-black-54'}>{title}</h2>
						)}

						{!subtitle ? (
							tagList.length > 0 ? (
								<p className="tags">
									Available On: <span>{tagList}</span>
								</p>
							) : (
								''
							)
						) : (
							<p>{subtitle}</p>
						)}
						{linkText && (
							<S.LinkWrapper>{generateLink(link, linkText)}</S.LinkWrapper>
						)}
					</div>
				</React.Fragment>
			)) || (
				<React.Fragment>
					<div className="content">
						{featuredCustomer && (
							<S.FeaturedCustomer>
								<p className="hide">Featured customer</p>
								{logo.file.contentType === 'image/svg+xml' ? (
									<img src={logo.file.url} width="100px" alt={logo.title} />
								) : (
									<Img
										fixed={logo.fixed}
										imgStyle={{
											objectFit: 'fill',
											maxWidth: '100px',
										}}
										alt={logo.title}
									/>
								)}
							</S.FeaturedCustomer>
						)}
						{header && (
							<S.FeaturedCustomer>
								<p className="hide">{header}</p>
							</S.FeaturedCustomer>
						)}
						{(featuredCustomer && (
							<S.H1 className={featuredCustomer ? '' : 'font-black-54'}>
								{title}
							</S.H1>
						)) ||
						isHeroHeader ? (
							<h1
								className={clsx(
									featuredCustomer || subHeading ? '' : 'font-black-54',
									blogPost && 'blog-title',
								)}
							>
								{title}
							</h1>
						) : (
							<h2
								className={clsx(
									featuredCustomer || subHeading ? '' : 'font-black-54',
									blogPost && 'blog-title',
								)}
							>
								{title}
							</h2>
						)}
						{!subtitle ? (
							tagList.length > 0 ? (
								<p className="tags">
									Available On: <span>{tagList}</span>
								</p>
							) : (
								''
							)
						) : (
							<p>{subtitle}</p>
						)}
						{buttonText && (
							<S.ButtonWrapper>
								{buttonLink ? generateButton(buttonLink, buttonText) : <></>}
								{secondaryButtonLink ? (
									generateButton(secondaryButtonLink, secondaryButtonText)
								) : (
									<></>
								)}
								{playButtonLink ? (
									generatePlayButton(playButtonLink.link, playButtonLink.text)
								) : (
									<></>
								)}
							</S.ButtonWrapper>
						)}
						{linkText && (
							<S.LinkWrapper>{generateLink(link, linkText)}</S.LinkWrapper>
						)}
						{featuredCustomer && (
							<S.ClientInfo>
								<p>{contactName}</p>
								<p>{contactTitle}</p>
							</S.ClientInfo>
						)}
						{blogPost && (
							<S.BlogDetailContainer>
								<S.AuthorDetails>
									<Img
										fluid={publisher.authorImage.fluid}
										style={{
											width: '50px',
											height: '50px',
											borderRadius: '50%',
										}}
									/>
									<S.AuthorName>
										<S.NameOfAuthor>{publisher.authorName}</S.NameOfAuthor>
										<S.BlogDetails>
											<span>{publisher.publishingDate}</span>
											<span>•</span>
											<span>{publisher.timeToRead}</span>
										</S.BlogDetails>
									</S.AuthorName>
								</S.AuthorDetails>
							</S.BlogDetailContainer>
						)}
					</div>
					{(hideImage && (
						<div className="image hide image-holder">
							{image.file ? (
								image.file.contentType === 'image/svg+xml' ||
								image.file.contentType === 'image/gif' ? (
									<img
										src={image.file.url}
										style={{
											objectFit: 'fill',
											maxHeight: subHeading ? '420px' : '400px',
											borderRadius: '10px',
											width: '100%',
										}}
										alt={image.title}
									/>
								) : image.file.contentType === 'video/mp4' ? (
									<video
										autoPlay
										muted
										loop
										style={getVideoStyle(videoBorderRadius)}
									>
										<source
											src={image.file.url}
											type={image.file.contentType}
										/>
									</video>
								) : (
									<Img
										fluid={image.fluid}
										alt={image.title}
										imgStyle={{
											objectFit: 'fill',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
											maxHeight: subHeading
												? '420px'
												: tagList.length > 0
												? '488px'
												: '400px',
											borderRadius: '10px',
										}}
									/>
								)
							) : (
								<Img
									fluid={image.fluid}
									alt={image.title}
									imgStyle={{
										objectFit: 'fill',
										maxHeight: subHeading ? '420px' : '400px',
										borderRadius: '10px',
									}}
								/>
							)}
						</div>
					)) || (
						<div id={blogPost && 'blog-image'} className="image image-holder">
							{image.file && image.file.contentType === 'video/mp4' ? (
								<video
									autoPlay
									muted
									loop
									style={getVideoStyle(videoBorderRadius)}
								>
									<source src={image.file.url} type={image.file.contentType} />
								</video>
							) : (
								<Img
									fluid={image.fluid}
									alt={image.title}
									imgStyle={{
										objectFit: blogPost ? 'cover' : 'fill',
										maxHeight: subHeading
											? '420px'
											: blogPost
											? '100%'
											: '400px',
										borderRadius: '10px',
									}}
								/>
							)}
						</div>
					)}
				</React.Fragment>
			)}
			{playButtonLink && (
				<StyledReactModal
					isOpen={modal}
					closeTimeoutMS={500}
					shouldFocusAfterRender={false}
					shouldCloseOnOverlayClick={true}
					style={customStyles}
					onRequestClose={handleOpenModal}
					portalClassName="portal-class"
				>
					<div className={`modal-content modal-default ${modal && 'open'}`}>
						<button onClick={handleOpenModal} className="close-button">
							×
						</button>
						<iframe
							title="youtubeVideo"
							src={generateEmbedLink(playButtonLink.link)}
							allow="autoplay;fullscreen;"
							frameborder="0"
							allowfullscreen="true"
							webkitallowfullscreen="true"
							mozallowfullscreen="true"
						></iframe>
					</div>
				</StyledReactModal>
			)}
		</S.Wrapper>
	);
};

export default InfoBox;
